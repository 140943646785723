import * as tslib_1 from "tslib";
import { BenutzerRolleDTO, BenutzerService } from "../core/api";
import { LoginService } from "bedag-angular-components";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable, of } from "rxjs";
import { NotificationService } from "../core/notification/notification.service";
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../core/api/api/benutzer.service";
import * as i5 from "../core/notification/notification.service";
var AuthenticationService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticationService, _super);
    function AuthenticationService(oauth, router, location, benutzerService, oauthService, notificationService) {
        var _this = _super.call(this, oauth, router, location) || this;
        _this.benutzerService = benutzerService;
        _this.oauthService = oauthService;
        _this.notificationService = notificationService;
        _this.loading = false;
        _this.EFO_ADMIN_AND_BETRIEBSLEITER = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
        _this.HPV_ADMININSTRATION_AND_PRAXISLEITUNG = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG];
        _this.EFO_ROLLEN = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSMITARBEITER, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
        _this.HPV_ROLLEN = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG, BenutzerRolleDTO.RolleEnum.HPVPRAXISSACHBEARBEITER, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION];
        _this.SUPERUSER = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR];
        _this.ADMINISTRATOR = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR, BenutzerRolleDTO.RolleEnum.EFOADMIN, BenutzerRolleDTO.RolleEnum.HPVADMIN];
        _this.userLoggedIn$ = new Observable(function (subscriber) {
            subscriber.next(_this.userLoggedIn);
            var previousState = _this.userLoggedIn;
            setInterval(function () {
                if (previousState !== _this.userLoggedIn) {
                    previousState = _this.userLoggedIn;
                    subscriber.next(_this.userLoggedIn);
                }
            }, 100);
        });
        return _this;
    }
    AuthenticationService.prototype.logout = function () {
        this.userDetails = null;
        this.benutzer = null;
        _super.prototype.logout.call(this);
    };
    AuthenticationService.prototype.login = function (path) {
        return _super.prototype.login.call(this, path).then(function (result) {
            return result;
        });
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        return this.userLoggedIn;
    };
    AuthenticationService.prototype.benutzerHasRolle = function (benutzerRollen, acceptedRoles) {
        return (benutzerRollen.filter(function (benutzerRolle) {
            return acceptedRoles.includes(benutzerRolle.rolle);
        }).length > 0);
    };
    AuthenticationService.prototype.canViewEfoKachel = function () {
        return this.hasAnyRolle(this.EFO_ROLLEN);
    };
    AuthenticationService.prototype.canViewHPVKachel = function () {
        return this.hasAnyRolle(this.HPV_ROLLEN);
    };
    AuthenticationService.prototype.isAdminOrLeitung = function () {
        return this.hasAnyRolle(this.EFO_ADMIN_AND_BETRIEBSLEITER.concat(this.HPV_ADMININSTRATION_AND_PRAXISLEITUNG));
    };
    AuthenticationService.prototype.isAdministrator = function () {
        return this.hasAnyRolle(this.ADMINISTRATOR);
    };
    AuthenticationService.prototype.hasAnyRolle = function (rollen) {
        var _this = this;
        if (!this.isLoggedIn()) {
            return of(false);
        }
        if (this.benutzer == null && !this.loading) {
            this.loading = true;
            this.benutzerService.self().subscribe(function (benutzer) {
                _this.benutzer = benutzer;
                _this.loading = false;
                return false;
            }, function (error) { return _this.notificationService.handleErrorShort("error.benutzer.self"); });
        }
        if (this.benutzer != null) {
            if (this.benutzerHasRolle(this.benutzer.rollen, this.SUPERUSER.concat(rollen))) {
                return of(true);
            }
            return of(false);
        }
        return of(false);
    };
    AuthenticationService.prototype.relogin = function () {
        this.oauthService.initImplicitFlow();
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.BenutzerService), i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i5.NotificationService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}(LoginService));
export { AuthenticationService };
