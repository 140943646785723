var FullScreenSpinnerComponent = /** @class */ (function () {
    function FullScreenSpinnerComponent() {
        // For so many milliseconds the spinner will be transparent after "working" has been set to true. Used to avoid flickering
        this.transparentForDurationMs = 0;
        this.transparent = false;
        this._show = false;
    }
    Object.defineProperty(FullScreenSpinnerComponent.prototype, "show", {
        get: function () {
            return this._show;
        },
        set: function (show) {
            if (!this._show && show) {
                this.displaySpinner();
            }
            else if (this._show && !show) {
                this.resetTimeout();
            }
            this._show = show;
        },
        enumerable: true,
        configurable: true
    });
    FullScreenSpinnerComponent.prototype.displaySpinner = function () {
        if (this.transparentForDurationMs > 0) {
            this.transparent = true;
            var that_1 = this;
            // set to non-transparent after the configured duration
            this.timeoutId = setTimeout(function () {
                that_1.transparent = false;
            }, this.transparentForDurationMs);
        }
        else {
            this.transparent = false;
        }
    };
    FullScreenSpinnerComponent.prototype.resetTimeout = function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
    };
    return FullScreenSpinnerComponent;
}());
export { FullScreenSpinnerComponent };
