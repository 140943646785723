import * as i0 from "@angular/core";
var AlertHolderService = /** @class */ (function () {
    function AlertHolderService() {
        this.alerts = [];
    }
    AlertHolderService.prototype.success = function (message) {
        this.add({ type: "success", message: message, error: null });
    };
    AlertHolderService.prototype.info = function (message) {
        this.add({ type: "info", message: message, error: null });
    };
    AlertHolderService.prototype.warning = function (message) {
        this.add({ type: "warning", message: message, error: null });
    };
    AlertHolderService.prototype.danger = function (message, error) {
        this.add({ type: "danger", message: message, error: error });
    };
    AlertHolderService.prototype.add = function (alert) {
        var _this = this;
        this.alerts.push(alert);
        if (alert.type === "success" || alert.type === "info") {
            setTimeout(function () { return _this.dismiss(alert); }, 5000);
        }
    };
    AlertHolderService.prototype.dismiss = function (alert) {
        var _this = this;
        setTimeout(function () { return _this.alerts = _this.alerts.filter(function (a) { return a !== alert; }); }, 150);
    };
    AlertHolderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertHolderService_Factory() { return new AlertHolderService(); }, token: AlertHolderService, providedIn: "root" });
    return AlertHolderService;
}());
export { AlertHolderService };
