import { Routes } from '@angular/router';
import { BetriebeModule } from "./betriebe/betriebe.module";
import { HomeComponent } from './home/home.component';
import { BenutzerModule } from "./benutzer/benutzer.module";
import { BerechtigungModule } from "./berechtigung/berechtigung.module";
var ɵ0 = function () { return BenutzerModule; }, ɵ1 = function () { return BetriebeModule; }, ɵ2 = function () { return BerechtigungModule; };
var routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'benutzer', loadChildren: ɵ0 },
    { path: 'betriebe', loadChildren: ɵ1 },
    { path: 'berechtigung', loadChildren: ɵ2 }
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
