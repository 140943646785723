import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
var AppComponent = /** @class */ (function () {
    function AppComponent(translateService, titleService) {
        this.translateService = translateService;
        this.titleService = titleService;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        var e_1, _a;
        this.translateService.setDefaultLang(window.environment.defaultLanguage);
        var language = localStorage.getItem('language');
        if (language != null) {
            this.translateService.use(language);
        }
        else {
            this.translateService.use(window.environment.defaultLanguage);
        }
        try {
            // we bundle the translations to prevent a flash of untranslated content
            // (this does not significantly increase bundle size unless you have dozens of translations)
            for (var _b = tslib_1.__values(window.environment.availableLanguages), _c = _b.next(); !_c.done; _c = _b.next()) {
                var lang = _c.value;
                this.translateService.setTranslation(lang, require("../i18n/locale-" + lang + ".json"));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.translateTitle();
        this.translateService.onLangChange.subscribe(function () {
            _this.translateTitle();
        });
    };
    AppComponent.prototype.translateTitle = function () {
        var _this = this;
        this.translateService.get('home.title').subscribe(function (translatedTitle) {
            _this.titleService.setTitle(translatedTitle);
        });
    };
    return AppComponent;
}());
export { AppComponent };
