import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { AlertHolder } from "bedag-angular-components";
import { environment } from 'src/environments/environment.prod';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "./auth/auth.service";
import { NavigationStart, Router } from "@angular/router";
var AppHeaderComponent = /** @class */ (function () {
    function AppHeaderComponent(alerts, translateService, authService, router) {
        var _this = this;
        this.alerts = alerts;
        this.translateService = translateService;
        this.authService = authService;
        this.router = router;
        this.languageNames = {
            'en': 'English',
            'de': 'Deutsch',
            'fr': 'Français',
            'it': 'Italiano',
            'rm': 'Rumantsch Grischun',
        };
        this.languages = environment.availableLanguages;
        this.show = false;
        router.events.forEach(function (event) {
            if (event instanceof NavigationStart) {
                _this.show = false;
            }
        });
    }
    AppHeaderComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.languages), _c = _b.next(); !_c.done; _c = _b.next()) {
                var lang = _c.value;
                this.translateService.setTranslation(lang, lang, true);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    AppHeaderComponent.prototype.languageName = function (language) {
        return this.languageNames[language] || language;
    };
    AppHeaderComponent.prototype.setLanguage = function (language) {
        localStorage.setItem('language', language);
        this.translateService.use(language);
    };
    AppHeaderComponent.prototype.toggleMobileNav = function () {
        this.show = !this.show;
    };
    return AppHeaderComponent;
}());
export { AppHeaderComponent };
