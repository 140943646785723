import { ApplikationEnum } from "../core/api/model/applikationEnum";
import { BenutzerRolleDTO } from "../core/api";
import { ApplicationService } from "./application.service";
import * as i0 from "@angular/core";
import * as i1 from "./application.service";
var BerechtigungService = /** @class */ (function () {
    function BerechtigungService(applikationService) {
        this.applikationService = applikationService;
    }
    BerechtigungService.prototype.getBenutzerApplikationen = function (benutzer) {
        var anwendungenAuswahl = Object.values(ApplikationEnum);
        if (!this.isBearbeitenderBenutzerAdmin(benutzer) && this.applikationService.isApplicationActive(ApplikationEnum.GEFPORTAL)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(function (anwendung) { return anwendung != ApplikationEnum.GEFPORTAL; });
        }
        if (!this.canManageEfoRoles(benutzer)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(function (anwendung) { return anwendung != ApplikationEnum.EIGENEFORMELONLINE; });
        }
        if (!this.canManageHPVRoles(benutzer)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(function (anwendung) { return anwendung != ApplikationEnum.HPV; });
        }
        return anwendungenAuswahl;
    };
    BerechtigungService.prototype.isBearbeitenderBenutzerAdmin = function (benutzer) {
        return benutzer.rollen.filter(function (r) { return r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR; }).length > 0;
    };
    BerechtigungService.prototype.canManageEfoRoles = function (benutzer) {
        return this.applikationService.isApplicationActive(ApplikationEnum.EIGENEFORMELONLINE) &&
            (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER));
    };
    BerechtigungService.prototype.canManageHPVRoles = function (benutzer) {
        return this.applikationService.isApplicationActive(ApplikationEnum.HPV) &&
            (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG));
    };
    BerechtigungService.prototype.hasBenutzerRolle = function (benutzer, rolle) {
        return benutzer.rollen.map(function (rolle) { return rolle.rolle; }).includes(rolle);
    };
    BerechtigungService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BerechtigungService_Factory() { return new BerechtigungService(i0.ɵɵinject(i1.ApplicationService)); }, token: BerechtigungService, providedIn: "root" });
    return BerechtigungService;
}());
export { BerechtigungService };
