import { ApplikationEnum } from "../core/api/model/applikationEnum";
import { environment } from "../../environments/environment";
import { KachelEnum } from "../core/api/model/kachelEnum";
import * as i0 from "@angular/core";
var ApplicationService = /** @class */ (function () {
    function ApplicationService() {
        var activeApplicationStringArray = environment.activeApplications;
        if (activeApplicationStringArray) {
            this.activeApplications = activeApplicationStringArray.map(function (a) { return ApplikationEnum[a]; });
        }
        else {
            this.activeApplications = Object.values(ApplikationEnum).map(function (ae) { return ae; });
        }
        var activeKachelnStringArray = environment.activeKacheln;
        if (activeKachelnStringArray) {
            this.activeKacheln = activeKachelnStringArray.map(function (k) { return KachelEnum[k]; });
        }
        else {
            this.activeKacheln = Object.values(KachelEnum).map(function (ke) { return ke; });
        }
    }
    ApplicationService.prototype.isApplicationActive = function (applikationEnum) {
        return this.activeApplications.includes(applikationEnum);
    };
    ApplicationService.prototype.isKachelActive = function (kachelEnum) {
        return this.activeKacheln.includes(kachelEnum);
    };
    ApplicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}());
export { ApplicationService };
