import { EventEmitter } from '@angular/core';
var AlertComponent = /** @class */ (function () {
    function AlertComponent() {
        this.dismiss = new EventEmitter();
        this.fading = false;
        this.icons = {
            danger: "fa-exclamation-circle",
            warning: "fa-exclamation-triangle",
            info: "fa-info-circle",
            success: "fa-check-circle"
        };
    }
    Object.defineProperty(AlertComponent.prototype, "dismissible", {
        get: function () {
            return this.dismiss.observers.length;
        },
        enumerable: true,
        configurable: true
    });
    return AlertComponent;
}());
export { AlertComponent };
