import { AlertHolderService } from "./alert-holder.service";
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "./alert-holder.service";
import * as i2 from "@ngx-translate/core";
var NotificationService = /** @class */ (function () {
    function NotificationService(alertHolderService, translateService) {
        this.alertService = alertHolderService;
        this.translateService = translateService;
    }
    NotificationService.prototype.handleErrorExtensive = function (messageKey, error, interpolateParams) {
        this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), error);
    };
    NotificationService.prototype.handleErrorShort = function (messageKey, interpolateParams) {
        this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), null);
    };
    NotificationService.prototype.handleSuccess = function (messageKey, interpolateParams) {
        this.alertService.success(this.translateService.instant(messageKey, interpolateParams));
    };
    NotificationService.prototype.addErrorMessage = function (messageKey, interpolateParams) {
        var message = this.translateService.instant(messageKey, interpolateParams);
        this.alertService.danger(message, null);
    };
    NotificationService.prototype.addWarnMessage = function (messageKey, interpolateParams) {
        var message = this.translateService.instant(messageKey, interpolateParams);
        this.alertService.danger(message, null);
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.AlertHolderService), i0.ɵɵinject(i2.TranslateService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
