import { OAuthService } from "angular-oauth2-oidc";
import { AuthenticationService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "@angular/router";
var LoginEventListenerService = /** @class */ (function () {
    function LoginEventListenerService(authService, oauthService, router) {
        this.authService = authService;
        this.oauthService = oauthService;
        this.router = router;
        this.isBeLogin = false;
        this.init();
    }
    LoginEventListenerService.prototype.init = function () {
        var _this = this;
        //subscribe to login-event, so we can distinguish if user logged in with be-login or otherwise
        this.oauthService.events.subscribe(function (e) {
            if (e.type === 'token_received') {
                _this.authService.userLoggedIn$.subscribe(function (isLoggedIn) {
                    if (isLoggedIn) {
                        _this.handleIdentityClaim();
                    }
                });
            }
            else if (e.type === 'logout') {
                if (_this.isBeLogin) {
                    localStorage.setItem("clearBeLoginSession", "true");
                    _this.isBeLogin = false;
                }
            }
            else if (e.type === 'discovery_document_loaded') {
                /* If we already have identity-claims, e.g. after a refresh,
                /* we can use them for finding out if we are logged in via BE-Login */
                if (_this.oauthService.getIdentityClaims()) {
                    _this.handleIdentityClaim();
                }
                if (localStorage.getItem("clearBeLoginSession") && !_this.authService.isLoggedIn()) {
                    _this.isBeLogin = false;
                    localStorage.removeItem("clearBeLoginSession");
                    window.location.href = environment.beLoginLogoutUrl;
                }
            }
        });
    };
    /* Keycloak is mapped to return a userinfo-attribute 'idpname' if user is logged in
    /* via be-login (value 'be-login') or adfs (value 'adfs'). Login with a keycloak-user does not return this attribute */
    LoginEventListenerService.prototype.handleIdentityClaim = function () {
        var identityClaims = this.oauthService.getIdentityClaims();
        if (identityClaims && identityClaims.hasOwnProperty("idpname")) {
            var ipd = identityClaims['idpname'];
            if (ipd == 'be-login') {
                this.isBeLogin = true;
            }
        }
        else {
            this.isBeLogin = false;
        }
    };
    LoginEventListenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginEventListenerService_Factory() { return new LoginEventListenerService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.OAuthService), i0.ɵɵinject(i3.Router)); }, token: LoginEventListenerService, providedIn: "root" });
    return LoginEventListenerService;
}());
export { LoginEventListenerService };
